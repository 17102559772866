<template>
  <div class="input-box">
    <div
      class="input-content"
      @keydown="keydown"
      @keyup="keyup"
      @paste="paste"
      @mousewheel="mousewheel"
      @input="inputEvent"
    >
      <input
        max="9"
        min="0"
        maxlength="1"
        data-index="0"
        v-model.trim.number="input[0]"
        type="number"
        ref="firstinput"
      />
      <input
        max="9"
        min="0"
        maxlength="1"
        data-index="1"
        v-model.trim.number="input[1]"
        type="number"
      />
      <input
        max="9"
        min="0"
        maxlength="1"
        data-index="2"
        v-model.trim.number="input[2]"
        type="number"
      />
      <input
        max="9"
        min="0"
        maxlength="1"
        data-index="3"
        v-model.trim.number="input[3]"
        type="number"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: ['', '', '', ''],
    };
  },
  props: {
    value: [String, Array]
  },
  created() {
    if (this.value && Array.isArray(this.value) && this.value.length === 4) {
      this.input = this.value;
    } else if (/^\d{4}$/.test(this.value.toString())) {
      this.input = this.value.toString().split('');
    }
  },
  methods: {
    // 解决一个输入框输入多个字符
    inputEvent(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      el.value = el.value
        .replace(/Digit|Numpad/i, '')
        .slice(0, 1);
      this.$set(this.input, index, el.value);
    },
    keydown(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      if (e.key === 'Backspace') {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, '');
        } else {
          if (el.previousElementSibling) {
            el.previousElementSibling.focus();
            this.$set(this.input, index - 1, '');
          }
        }
      } else if (e.key === 'Delete') {
        if (this.input[index].length > 0) {
          this.$set(this.input, index, '');
        } else {
          if (el.nextElementSibling) {
            this.$set(this.input, (index = 1), '');
          }
        }
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === 'Home') {
        el.parentElement.children[0] && el.parentElement.children[0].focus();
      } else if (e.key === 'End') {
        el.parentElement.children[this.input.length - 1] &&
          el.parentElement.children[this.input.length - 1].focus();
      } else if (e.key === 'ArrowLeft') {
        if (el.previousElementSibling) {
          el.previousElementSibling.focus();
        }
      } else if (e.key === 'ArrowRight') {
        if (el.nextElementSibling) {
          el.nextElementSibling.focus();
        }
      } else if (e.key === 'ArrowUp') {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.key === 'ArrowDown') {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      }
    },
    keyup(e) {
      var index = e.target.dataset.index * 1;
      var el = e.target;
      // 解决输入e的问题
      el.value = el.value
        .replace(/Digit|Numpad/i, '')
        .slice(0, 1);
      if (el.value) {
        // 必须在这里符直，否则输入框会是空值
        this.$set(this.input, index, el.value);
        el.nextElementSibling && el.nextElementSibling.focus();
        if (index === 3) {
          if (this.input.join('').length === 4) {
            document.activeElement.blur();
            this.$emit('input', this.input.join(''));
          }
        }
      } else {
        if (this.input[index] === '') {
          this.$set(this.input, index, '');
        }
      }
    },
    mousewheel(e) {
      var index = e.target.dataset.index;
      if (e.wheelDelta > 0) {
        if (this.input[index] * 1 < 9) {
          this.$set(this.input, index, (this.input[index] * 1 + 1).toString());
        }
      } else if (e.wheelDelta < 0) {
        if (this.input[index] * 1 > 0) {
          this.$set(this.input, index, (this.input[index] * 1 - 1).toString());
        }
      } else if (e.key === 'Enter') {
        if (this.input.join('').length === 4) {
          document.activeElement.blur();
          this.$emit('input', this.input.join(''));
        }
      }
    },
    paste(e) {
      // 当进行粘贴时
      e.clipboardData.items[0].getAsString((str) => {
        if (str.toString().length === 4) {
          this.input = str.split('');
          document.activeElement.blur();
          this.$emit('input', this.input.join(''));
        } else {
          // 如果粘贴内容不合规，清除所有内容
          this.input[0] = ['', '', '', ''];
        }
      });
    },
  },
  mounted() {
    // 等待dom渲染完成，在执行focus,否则无法获取到焦点
    this.$nextTick(() => {
      this.$refs.firstinput.focus();
    });
  },
};
</script>

<style scoped lang="scss">
.input-box {
  .input-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #bcbdbf;
    padding: 14px;
    border-radius: 5px;

    input {
      color: inherit;
      font-family: inherit;
      border: 0;
      outline: 0;
      border: 1px solid #bcbdbf;
      border-radius: 5px;
      font-size: 30px;
      width: 35px;
      height: 35px;
      text-align: center;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }
}
</style>
